<template>
  <div>
    <div class="sub-header-div row mx-auto">
      <div :class="`col-sm-6 d-flex flex-column ${isMobile ? 'm-5' : 'mx-10 mt-20'}`">
        <span :class="`font-bold color-white ${isMobile ? 'font-30 my-2' : 'font-50 my-5'}`">Sei dabei!</span>
        <span :class="`color-white ${isMobile ? 'font-18' : 'font-25'}`">
          Wir wissen ganz genau, was den Veranstalter anspricht und haben dafür eine
Komplettlösung für Sie in unserer App gestalten lassen. Ob Festivals, Events,
Clubveranstaltungen und Sportevents halten Sie Ihre Gäste auf dem Laufenden und
lassen Sie sich von Ihren Gästen inspirieren, denn sie posten ihre Eventerlebnisse und
präsentieren zudem der ganzen Welt Ihre Marke in der App. Mit unserem einfachen
Ticketsystem, der einfach zu erzielenden Reichweite und Steigerung des Umsatzes bis
zu 20 % durch unser Sponsoring erobern wir die Herzen des Veranstalters.
        </span>
      </div>
      <div class="col-sm-5 center">
        <img :class="isMobile ? 'm-10' : 'm-20'" :src="assets.mobile1" :width="isMobile ? '80%' : '50%'" />
      </div>
    </div>
    <p :class="`center font-bold ${isMobile ? 'font-20 my-10' : 'font-30 my-20'}`">ALLES IN EINEM FÜR IHRE VERANSTALTUNG</p>
    <div class="row mx-auto mt-10">
      <div class="col-sm-5 center">
        <img :src="assets.mobile2" :width="isMobile ? '80%' : '55%'" />
      </div>
      <div class="col-sm-6 d-flex flex-column">
        <div class="m-2 row box-purple vertical-center">
          <img :class="`col-2 ${isMobile ? 'p-2' : 'p-8'}`" :src="assets.community" />
          <div :class="`col d-flex flex-column ${isMobile ? 'm-2' : 'm-5'}`">
            <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-25'}`">Community</span>
            <span :class="`color-white ${isMobile ? 'font-12 mt-2' : 'font-20 mt-5'}`">
              Bauen Sie sich Ihre eigene Community + mit unserem kostenfreien Sponsoring auf.
              <br/>
              Ihre Käufer/-innen wandeln sich automatisch um zu Ihrem Follower.
            </span>
          </div>
        </div>
        <div class="m-2 row box-purple vertical-center">
          <img :class="`col-2 ${isMobile ? 'p-2' : 'p-8'}`" :src="assets.scanning" />
          <div :class="`col d-flex flex-column ${isMobile ? 'm-2' : 'm-5'}`">
            <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-25'}`">Check-In</span>
            <span :class="`color-white ${isMobile ? 'font-12 mt-2' : 'font-20 mt-5'}`">
              Beim Einlass ist nichts Weiteres nötig als den QR-Code vorzuzeigen.
              <br/>
              Schnelles Scannen vermeidet beim Einlass lange Wartezeiten.
            </span>
          </div>
        </div>
        <div class="m-2 row box-purple vertical-center">
          <img :class="`col-2 ${isMobile ? 'p-2' : 'p-8'}`" :src="assets.withdraw" />
          <div :class="`col d-flex flex-column ${isMobile ? 'm-2' : 'm-5'}`">
            <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-25'}`">Dashboard und Auszahlung</span>
            <span :class="`color-white ${isMobile ? 'font-12 mt-2' : 'font-20 mt-5'}`">
              Bestimmen Sie individuell Ihre eigene Preisstruktur.
              <br/>
              Kontrollieren und halten Sie in Echtzeit Ihre Umsätze im Blick.
              <br/>
              Eine individuelle Auszahlung ist für Sie jederzeit verfügbar.
              <br/>
              Schnelle Abrechnung nach dem Event spätestens am Folgetag.
            </span>
          </div>
        </div>
        <div class="m-2 row box-purple vertical-center">
          <img :class="`col-2 ${isMobile ? 'p-2' : 'p-8'}`" :src="assets.sales_euro" />
          <div :class="`col d-flex flex-column ${isMobile ? 'm-2' : 'm-5'}`">
            <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-25'}`">Finanz-Controllings</span>
            <span :class="`color-white ${isMobile ? 'font-12 mt-2' : 'font-20 mt-5'}`">Detaillierte Darstellung aller Check-In Gäste, Umsätze, Bestellvorgänge und Rückerstattungen.</span>
          </div>
        </div>
      </div>
    </div>
    <div :class="`row mx-auto create-account-div ${isMobile ? 'mt-5' : 'mt-20'}`">
      <span class="col-sm-1" v-if="!isMobile">&nbsp;</span>
      <div :class="`col-sm mx-5 box-white d-flex flex-column center ${isMobile ? 'mt-10' : 'my-30'}`">
        <img class="mt-5" :src="assets.account_verify" width="30%" />
        <span :class="`my-5 font-bold ${isMobile ? 'font-18' : 'font-25'}`">Erstellen Sie jetzt kostenfrei Ihr Business-Konto.</span>
      </div>
      <span class="col-sm-1" v-if="!isMobile">&nbsp;</span>
      <div :class="`col-sm mx-5 box-white d-flex flex-column center ${isMobile ? 'mt-5 mb-10' : 'mx-5 my-30'}`">
        <img class="mt-5" :src="assets.ticket_selling" width="30%" />
        <span :class="`my-5 font-bold ${isMobile ? 'font-18' : 'font-25'}`">Erstellen Sie Ihre anstehenden Events und schon starten Sie mit dem Ticketverkauf Ihrer Events.</span>
      </div>
      <span class="col-sm-1" v-if="!isMobile">&nbsp;</span>
    </div>
    <div :class="`my-10 vertical-center`">
      <span :class="`ml-auto font-bold ${isMobile ? 'font-25  mr-5 mt-3' : 'font-40 mt-5 mr-10'}`">JOIN US</span>
      <img class="mr-auto" :src="assets.logo_text" :height="isMobile ? 30 : 60" />
    </div>
    <video
      width="100%"
      :src="videoUrl"
      :autoplay="autoplay"
      loop muted playsinline
      preload="metadata" oncontextmenu="return false;"
      onmouseover="this.play()" onmouseout="this.pause();" onclick="this.muted=!this.muted;">
    </video>
    <div class="sub-header-div d-flex flex-column center">
      <span :class="`my-10 font-bold color-white ${isMobile ? 'font-20' : 'font-30'}`">DOWNLOAD</span>
      <div class="mb-10">
        <a class="m-10" :href="APP_STORE_URL" target="_blank" v-if="platform === 'Desktop' || platform === 'iOS'">
          <img class="store-image" :src="assets.app_store" />
        </a>
        <a class="m-10" :href="GOOGLE_PLAY_URL" target="_blank" v-if="platform === 'Desktop' || platform === 'Android'">
          <img class="store-image" :src="assets.google_play" />
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
  * {
    line-height: 1.2;
  }
  .sub-header-div {
    background-image: url('/media/image/sub_header.jpg');
    background-size: cover;
  }
  .create-account-div {
    background-image: url('/media/image/create_account_bg.jpg');
    background-size: cover;
  }
</style>

<script>
import logo_text from '@/assets/logo/logo_text.png';
import google_play from '@/assets/images/google_play.png';
import app_store from '@/assets/images/app_store.png';
import mobile1 from '@/assets/images/mobile1.png';
import mobile2 from '@/assets/images/mobile2.png';
import community from '@/assets/images/community.png';
import scanning from '@/assets/images/scanning.png';
import withdraw from '@/assets/images/withdraw.png';
import sales_euro from '@/assets/images/sales_euro.png';
import account_verify from '@/assets/images/account_verify.png';
import ticket_selling from '@/assets/images/ticket_selling.png';

import { getPlatform } from '../../../functions';

export default {
  name: 'BecomeOrganizer',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      assets: {
        logo_text,
        google_play,
        app_store,
        mobile1,
        mobile2,
        community,
        scanning,
        withdraw,
        sales_euro,
        account_verify,
        ticket_selling
      },
      videoUrl: 'https://firebasestorage.googleapis.com/v0/b/eventbox-c7c8f.appspot.com/o/ForWeb%2FIMG_9528.MOV?alt=media&token=b42ccf0e-db42-4e3d-9fa2-15ad728f45a8',
      autoplay: getPlatform() !== 'Desktop',
      platform: getPlatform()
    }
  }
};
</script>